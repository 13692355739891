

.PDS_Poll button.pds-vote-button {
	--poll-button-color: #bc366e;
	--poll-button-border-color: #ea6594;

	background-color: var(--poll-button-color);
	border: 1px solid var(--poll-button-border-color);
	border-radius: 5px;
	color: var(--color-neutral-white);
	display: block;
	padding: 4px 8px;
}

.PDS_Poll .pds-vote .pds-votebutton-outer {
	height: auto !important;
}

.PDS_Poll > .pds-box:not(:has(> .pds-box-outer)) > .pds-box-inner > div {
	display: flex;
	flex-direction: column;
	margin: 0 !important;
	row-gap: var(--spacing-xs);
}

.PDS_Poll > .pds-box:not(:has(> .pds-box-outer)) > .pds-box-inner > div div {
	height: auto !important;
	margin-top: 0 !important;
}

.PDS_Poll > .pds-box:not(:has(> .pds-box-outer)) > .pds-box-inner > div span input {
	border: 1px solid var(--color-neutral-dark-60);

	&:focus-visible {
		outline: none;
	}
}
